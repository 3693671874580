



























































































import { Component, Vue } from "vue-property-decorator";
import Snack from "@/components/Snack.vue";

import restService from "@/services/restService";

// import alertsStore, { AlertsStoreState } from "@/stores/alertsStore";

import SelectPlugTypeDialog from "@/components/SelectPlugTypeDialog.vue";
// import AutoRefresh from "@/components/AutoRefresh.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

import usersStore, { UsersStoreState } from "@/stores/usersStore";
import customersService from "@/services/customersService";
import energyBoxesStore, {
  EnergyBoxesStoreState,
} from "@/stores/energyBoxesStore";

// import { TransactionModel } from "@/models/TransactionModel";
import { User } from "@/models/User";
import { EnergyBox } from "@/models/EnergyBox";
import { Customer } from "@/models/Customer";

@Component({
  components: {
    ConfirmDialog,
    // AutoRefresh,
    SelectPlugTypeDialog,
    Snack,
  },
})
export default class Hmi extends Vue {
  menu: boolean = false;

  // alertsStoreState: AlertsStoreState = alertsStore.state;

  usersStoreState: UsersStoreState = usersStore.state;
  energyBoxesState: EnergyBoxesStoreState = energyBoxesStore.state;

  get selectPlugTypeDialog(): SelectPlugTypeDialog {
    return this.$refs.SelectPlugTypeDialog as SelectPlugTypeDialog;
  }

  async selectPlugType(): Promise<void> {
    try {
      const type: number = await this.selectPlugTypeDialog.showDialog(
        this.customer?.plug_elec
      );
      if (this.customer?.id) {
        try {
          await customersService.postCustomer(this.customer?.id, {
            plug_elec: type,
          });
          this.refresh();
        } catch (error) {
          this.errorNotify(
            this.$t('menu.vos-preferences-nont-pas-pu-etre-mises-a-jour'),
            error as Error
          );
        }
      }
      // nothing o do if cancel
    } catch (error) {
      console.log(error);
    }
  }

  async stop(): Promise<void> {
    const confirm = await this.showConfirmDialog(
      this.$t('menu.terminer-la-distribution'),
      this.$t('menu.cette-action-coupera-leau-et-lelectricite'),
      null,
      this.$t('menu.terminer'),
      this.$t('menu.annuler')
    );
    if (!confirm) {
      return;
    }

    try {
      await usersStore.stop();
      this.successNotify(this.$t('menu.fin-de-la-distribution'));
      this.refresh();
    } catch (error) {
      this.errorNotify(this.$t('menu.la-distribution-na-pas-pu-etre-arretee'), error);
    }
  }

  get isDelivering(): boolean {
    return !this.boxes.every((box) =>
      box.plugs.every((plug) => plug.transaction == null)
    );
  }

  get boxes(): EnergyBox[] {
    return this.energyBoxesState.boxes;
  }

  get customer(): Customer | null {
    return this.usersStoreState.customer;
  }

  get user(): User | null {
    return this.usersStoreState.user;
  }

  // get alerts(): TransactionModel[] {
  //   return this.alertsStoreState.alerts;
  // }

  async refresh(): Promise<void> {
    try {
      await Promise.all([usersStore.refresh(), energyBoxesStore.refresh()]);
    } catch (e) {
      this.errorNotify(this.$t('menu.impossible-de-lister-les-bornes'), e as Error);
    }
  }

  disconnect(): void {
    restService.disconnect();
  }

  mounted(): void {
    this.refresh();
  }
}
