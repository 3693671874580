// import { PlugModel } from "@/models/Plug";
// import { TransactionModel } from "@/models/Transaction";
import { EnergyBox } from "@/models/EnergyBox";
import energyBoxesService from "@/services/energyBoxesService";

export interface EnergyBoxesStoreState {
  boxes: EnergyBox[];
}

class EnergyBoxesStore {
  state: EnergyBoxesStoreState = {
    boxes: [],
  };

  async refresh(): Promise<void> {
    this.state.boxes = await energyBoxesService.list()
  }

  // refreshAvailableList(): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     plugsService
  //       .listPlugs({ only_available: true })
  //       .then((result) => {
  //         this.state.availablePlugs = result;
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // }

  // activate(id: number, qty: number): Promise<TransactionModel> {
  //   return new Promise((resolve, reject) => {
  //     plugsService
  //       .activate(id, qty)
  //       .then((result) => {
  //         resolve(result);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // }

  // deactivate(id: number): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     plugsService
  //       .deactivate(id)
  //       .then(() => {
  //         resolve();
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // }
}

export default new EnergyBoxesStore();
