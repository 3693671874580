var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "400px"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "justify-center headline",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }), _c('v-card-text', {
    staticClass: "warning--text"
  }, [_vm._v(_vm._s(_vm.warning))]), _c('v-card-text', {
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  }), _c('v-card-actions', {
    staticClass: "pb-0"
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "block": "",
      "color": "error"
    },
    on: {
      "click": _vm.noBtn
    }
  }, [_vm._v(_vm._s(_vm.no))])], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.yesBtn
    }
  }, [_vm._v(_vm._s(_vm.yes))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }