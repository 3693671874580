var render = function () {
  var _vm$user;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('ConfirmDialog'), _c('SelectPlugTypeDialog', {
    ref: "SelectPlugTypeDialog"
  }), _c('Snack'), _c('v-app-bar', {
    staticClass: "white",
    attrs: {
      "app": "",
      "height": "60"
    }
  }, [_c('v-badge', {
    staticClass: "mr-2",
    attrs: {
      "color": "error",
      "overlap": "",
      "content": false,
      "value": 0,
      "offset-x": "18",
      "offset-y": "18"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.menu = !_vm.menu;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-menu")])], 1)], 1), _c('v-spacer'), _c('v-spacer'), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-img', {
    staticClass: "shrink mr-2",
    attrs: {
      "contain": "",
      "src": "/Logo_DC-Plaisance_couleur.PNG",
      "transition": "scale-transition",
      "width": "120"
    }
  })], 1)], 1), _c('v-navigation-drawer', {
    staticClass: "background-app",
    staticStyle: {
      "position": "fixed",
      "top": "0",
      "left": "0",
      "overflow-y": "scroll",
      "z-index": "20"
    },
    attrs: {
      "left": "",
      "absolute": "",
      "temporary": ""
    },
    model: {
      value: _vm.menu,
      callback: function callback($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-list', {
    attrs: {
      "link": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "disabled": !_vm.isDelivering
    },
    on: {
      "click": function click($event) {
        return _vm.stop();
      }
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-6"
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.isDelivering ? 'primary' : 'secondary'
    }
  }, [_vm._v("mdi-power-plug-off-outline")])], 1), _c('v-list-item-title', {
    class: _vm.isDelivering ? 'primary--text' : 'secondary--text'
  }, [_vm._v(_vm._s(_vm.$t('menu.terminer-distribution')))])], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "link": "",
      "to": "/hmi/history"
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-6"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-history")])], 1), _c('v-list-item-title', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.$t('menu.consommations')))])], 1), _c('v-list-item', {
    attrs: {
      "link": "",
      "to": "/hmi/map"
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-6"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-map")])], 1), _c('v-list-item-title', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.$t('menu.carte')))])], 1), _c('v-list-item', {
    attrs: {
      "link": "",
      "to": "/hmi/qrcode"
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-6"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-qrcode")])], 1), _c('v-list-item-title', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.$t('menu.qr-code')))])], 1), _c('v-list-item', {
    attrs: {
      "link": "",
      "to": "/hmi/search"
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-6"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-magnify")])], 1), _c('v-list-item-title', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.$t('menu.chercher-borne')))])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "link": "",
      "two-line": ""
    },
    on: {
      "click": _vm.disconnect
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-6"
  }, [_c('v-icon', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v("mdi-logout")])], 1), _c('v-list-item-title', [_c('div', {
    staticClass: "error--text"
  }, [_vm._v(_vm._s(_vm.$t('menu.se-deconnecter')))]), _c('div', {
    staticClass: "caption"
  }, [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.username))])])], 1)], 1), _c('v-main', [_c('router-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }