import { CommonService } from "@/services/commonService";

import { EnergyBox, IEnergyBox } from "@/models/EnergyBox";
import { Plug } from "@/models/Plug";
import { Transaction } from "@/models/Transaction";

const BASE_URL = "api/mobile/energyboxes/";

export interface IEnergyBoxInfo {
  updated: boolean
  , box: EnergyBox
}

class EnergyBoxesService extends CommonService {
  private energyBoxDataToInstance(dataEnergyBox: IEnergyBox): EnergyBox {
    const plugs: Plug[] = dataEnergyBox.plugs.map((dataPlug) =>
      Object.assign(new Plug(), {
        ...dataPlug,
        transaction: dataPlug.transaction
          ? Object.assign(new Transaction(), dataPlug.transaction)
          : null,
      })
    );

    return Object.assign(new EnergyBox(), { ...dataEnergyBox, plugs });
  }

  async list(): Promise<EnergyBox[]> {
    const result = (await this.restService.get(BASE_URL));
    return result.data.map((item: IEnergyBox) => this.energyBoxDataToInstance(item));
  }

  async get(localisation: number): Promise<IEnergyBoxInfo> {
    const result = await this.restService.get(`${BASE_URL}${localisation}/`);
    return {updated: result.data.updated, box: this.energyBoxDataToInstance(result.data.box)};
  }
}

export default new EnergyBoxesService();
