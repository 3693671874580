
















import { TranslateResult } from "vue-i18n";
import { Vue, Component } from "vue-property-decorator";
import messageStore, {
  // TRejectFunction,
  TResolveFunction,
} from "@/stores/messageStore";

@Component
export default class ConfirmDialog extends Vue {
  messageState = messageStore.state;

  get show(): boolean {
    return this.messageState.confirmDialog.show;
  }
  get title(): string | TranslateResult {
    return this.messageState.confirmDialog.title;
  }
  get content(): string  | TranslateResult{
    return this.messageState.confirmDialog.content;
  }
  get warning(): string | TranslateResult | null {
    return this.messageState.confirmDialog.warning;
  }

  get yes(): string | TranslateResult {
    return this.messageState.confirmDialog.yes;
  }
  get no(): string | TranslateResult {
    return this.messageState.confirmDialog.no;
  }

  get confirmYesAction(): TResolveFunction | null {
    return this.messageState.confirmDialog.confirmYesAction;
  }

  // get confirmNoAction(): TRejectFunction | null {
  //   return this.messageState.confirmDialog.confirmNoAction;
  // }

  yesBtn(): void {
    if (this.confirmYesAction) {
      this.confirmYesAction(true);
    }
    this.messageState.confirmDialog.show = false;
  }

  noBtn(): void {
    if (this.confirmYesAction) {
      this.confirmYesAction(false);
    }
    this.messageState.confirmDialog.show = false;
  }
}
