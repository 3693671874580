























import { Vue, Component } from "vue-property-decorator";

import {TYPES_ELEC} from "@/models/Plug";

@Component
export default class ConfirmDialog extends Vue {
  TYPES_ELEC = TYPES_ELEC;

  show: boolean = false;
  currentType: number = 0;
  resolve: ((type: number) => void) | null = null;
  reject: ((e: Error) => void) | null = null;

  showDialog(currentType: number = 0): Promise<number> {
    return new Promise((resolve, reject) => {
      this.show = true;
      this.currentType = currentType;
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  select(type: number): void {
    if (this.resolve) {
      this.resolve(type);
    }
    this.show = false;
  }

  cancel(): void {
    if (this.reject) {
      this.reject(new Error("Canceled"));
    }
    this.show = false;
  }
}
