import { Common, TIMEOUT_ERROR, TIMEOUT_WARNING, TYPE_ELEC, TYPE_WATER } from "./Common";
import { IPlug, Plug } from "./Plug";

export interface IEnergyBox {
  id: number;
  label: string;
  localisation: number;
  status: string;
  last_ok: string;
  gateway_last_ok: string;
  lat: number;
  lng: number;

  plugs: IPlug[];
}

export class EnergyBox extends Common implements IEnergyBox {
  id: number = 0;
  label: string = "";
  localisation: number = 0;
  status: string = "";
  last_ok: string = "";
  gateway_last_ok: string = "";
  lat: number = 0;
  lng: number = 0;
  plugs: Plug[] = [];

  isHere(): boolean {
    for (const plug of this.plugs) {
      if (plug.transaction) {
        return true;
      }
    }
    return false;
  }

  getBoxColor(lastUpdateMs: number, okColor: string = "success"): string {
    if (this.isOlderThan(lastUpdateMs, this.last_ok, TIMEOUT_ERROR)) {
      return "error";
    }
    if (this.isOlderThan(lastUpdateMs, this.last_ok, TIMEOUT_WARNING)) {
      return "warning";
    }

    return okColor;
  }

  getWaterColor(kind: number): string {
    for (const plug of this.plugs) {
      if (
        plug.type == TYPE_WATER &&
        plug.isFree(kind)
      ) {
        return "primary";
      }
    }
    return "grey";
  }

  getElecColor(kind: number): string {
    for (const plug of this.plugs) {
      if (
        plug.type == TYPE_ELEC &&
        plug.isFree(kind)
      ) {
        return "primary";
      }
    }
    return "grey";
  }
}
